import React from "react";
import "./global.css";
import Header from "./components/Header";
import Screen1 from "./components/Screen1";
import left1 from "./images/left1.webp";
import right1 from "./images/right1.webp";
import left2 from "./images/left2.webp";
import right2 from "./images/right2.webp";
import Screen2 from "./components/Screen2";
import HRule from "./components/HRule";
import casino1 from "./images/grid1.webp";
import casino2 from "./images/grid2.webp";
import casino3 from "./images/grid3.webp";
import casino4 from "./images/grid4.webp";
import casino5 from "./images/grid5.webp";
import casino6 from "./images/grid6.webp";
import casino7 from "./images/grid7.webp";
import casino8 from "./images/grid8.webp";
import casino9 from "./images/grid9.webp";
import casino10 from "./images/grid10.webp";
import casino11 from "./images/grid11.webp";
import casino12 from "./images/grid12.webp";

import Screen3 from "./components/Screen3";
import Screen4 from "./components/Screen4";
import Screen5 from "./components/Screen5";
import Screen6 from "./components/Screen6";
import { scrollAnimation } from "./components/constant/animation";

function App() {
  // window.addEventListener('scroll', scrollAnimation)

  return (
    <main className="bg-bg text-font font-['Serif'] font-normal">
      <Header />
      {/* <HRule /> */}
      <Screen1 />
      <HRule />
      <Screen2 />
      <HRule />
      <div className="flex justify-between py-10 items-center overflow-hidden">
        <img src={left1} alt="casino images" className="revealRight " />
        <img src={right1} alt="casino images" className="revealLeft " />
      </div>
      <HRule />
      <div className="pt-20 pb-10 grid grid-cols-2 grid-rows-5 md:grid-cols-3 md:grid-rows-4 lg:grid-cols-4 lg:grid-rows-3  px-2 gap-2 overflow-hidden ">
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino1} alt="casino pictures" className="revealLeft" />
          </div>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino2} alt="casino pictures" className="revealRight" />
          </div>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino3} alt="casino pictures" className="revealRight" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino4} alt="casino pictures" className="revealLeft" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino5} alt="casino pictures" className="revealLeft" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino6} alt="casino pictures" className="revealRight" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino7} alt="casino pictures" className="revealRight" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino8} alt="casino pictures" className="revealLeft" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino9} alt="casino pictures" className="revealLeft" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino10} alt="casino pictures" className="revealRight" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino11} alt="casino pictures" className="revealRight" />
          </div>
        </a>{" "}
        <a
          href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-xs">
            <img src={casino12} alt="casino pictures" className="revealLeft" />
          </div>
        </a>
      </div>
      <Screen3 />
      <HRule />
      <div className="flex justify-between py-10 items-center overflow-hidden">
        <img src={left2} alt="casino images" className="revealRight " />
        <img src={right2} alt="casino images" className="revealLeft" />
      </div>
      <HRule />
      <Screen4 />
      <HRule />
      <Screen5 />
      <HRule />
      <Screen6 />
    </main>
  );
}

export default App;
