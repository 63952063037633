import React from 'react'
import Button from './Button'

const Screen3 = () => {
  return (
    <section>
      <div className="flex flex-col gap-5 container pb-5">
        <h3 className="textGradient font-extrabold text-3xl md:text-center">
          Place your BET in our IDs
        </h3>
        <p className="font-semibold text-xl md:text-center">
          With Our Step-By-Step Guide For Beginners,
          <br /> Get Started Playing. Start Learning Now.. 24/7 ACTIVE.
        </p>
        <h4 className="text-white text-xl md:text-center">
          We Deal Only On whatsapp
        </h4>
        <div className="flex flex-col justify-center items-center gap-5">
          <Button number={"+977 980111111"} />
          <Button number={"+977 980222222"} />
        </div>
      </div>
    </section>
  )
}

export default Screen3
