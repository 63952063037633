const scrollAnimation = () => {
  const revealRight = document.querySelectorAll(".revealRight");
  console.log(revealRight);
  for (let i = 0; i < revealRight.length; i++) {
    let windowHeight = window.innerHeight;
    let elementTop = revealRight[i].getBoundingClientRect().top;
    let elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      revealRight[i].classList.add("active");
    } else {
      revealRight[i].classList.remove("active");
    }
  }
  const revealLeft = document.querySelectorAll(".revealLeft");
  console.log(revealLeft);
  for (let i = 0; i < revealLeft.length; i++) {
    let windowHeight = window.innerHeight;
    let elementTop = revealLeft[i].getBoundingClientRect().top;
    let elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      revealLeft[i].classList.add("active");
    } else {
      revealLeft[i].classList.remove("active");
    }
  }
};

export { scrollAnimation };
