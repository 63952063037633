import React from 'react'
import Lotus from '../images/1.png'
import lotus365days from '../images/4.png'
import Sky from '../images/lotus365club.png'
import d247 from '../images/3.png'
import Dian from '../images/2.png'
import { scrollAnimation } from './constant/animation'

const Screen2 = () => {
  window.addEventListener('scroll', scrollAnimation)

  return (
    <section className="container py-10">
      <h3 className="text-center font-medium text-4xl pb-5">Available Sites</h3>
      <div className="flex flex-col gap-5 justify-center items-center">
        <div className="flex gap-10 flex-wrap justify-center overflow-hidden items-center flex-1">
          <a href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days" target="_blank" rel="noreferrer">
            <img
              src={Lotus}
              alt="Sky site"
              className="revealRight w-[300px] rounded-lg shadow-lg shadow-[#FFD700]"
            />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Dian}
              alt="Lotus site"
              className="revealLeft w-[300px] rounded-lg shadow-lg shadow-[#FFD700]"
            />
          </a>
          {/* </div> */}
          {/* <div className="flex gap-10 flex-wrap justify-center items-center overflow-hidden flex-1"> */}
          <a href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days" target="_blank" rel="noopener noreferrer">
            <img
              src={d247}
              alt="Sky site"
              className="revealRight w-[300px] flex-1 rounded-lg shadow-lg shadow-[#FFD700]"
            />
          </a>
          <a href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days" target="_blank" rel="noreferrer">
            <img
              src={Sky}
              alt="Sky site"
              className="revealRight w-[300px] flex-1 rounded-lg shadow-lg shadow-[#FFD700]"
            />
          </a>
          <a href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to lotus365days" target="_blank" rel="noreferrer">
            <img
              src={lotus365days}
              alt="Lotus site"
              className="revealLeft w-[300px] flex-1 rounded-lg shadow-lg shadow-[#FFD700]"
            />
          </a>
        </div>
        {/* <div className="flex gap-2 flex-wrap justify-center items-center overflow-hidden flex-1">
          <img src={Sky} alt="Lotus site" className="revealLeft" />
        </div> */}
      </div>
    </section>
  )
}

export default Screen2
