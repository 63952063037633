import React, { useEffect, useState } from 'react'
import logo from '../images/lotus365days.png'

const Header = () => {

  const [navColor, setnavColor] = useState("#292727");

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#012C4B") : setnavColor("#012C4B");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <><header className="flex justify-center items-center h-52 py-3 sticky-content " style={{
      backgroundColor: navColor,
      transition: "all 1s",
    }}>
      <img src={logo} alt="logo" className='h-[150px]' />
    </header><div className="border border-font sticky-content-1" /></>
  )
}

export default Header
