import React from "react";
import whatsapp from "../images/whatsapp.png";

const Button = ({number}) => {
  return (
    <><a href="https://api.whatsapp.com/send?phone=+9779808929555&amp;text=Welcome to ZetBet" target="_blank" rel="noreferrer"><div role={"button"} className="btn">
      <img
        src={whatsapp}
        style={{ height: "2.5rem", paddingTop: "0.5rem" }}
        alt="Whatsapp" />
      <button className="">{number}</button>
    </div></a></>
  );
};

export default Button;
