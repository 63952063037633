import React from 'react'
import ncdex from '../images/6.png'
import mcx from '../images/5.png'

const Screen5 = () => {
  return (
    <section>
      <div className="py-10">
        <h3 className="textGradient font-extrabold text-4xl text-center pb-5">
          Stock Market
        </h3>
        <div className="h-80 md:h-48 flex  items-center justify-center">
          <div className="flex flex-wrap items-center justify-center gap-5">
            <img src={ncdex} alt="ncdex" width="300px" className='rounded-lg shadow-lg shadow-[#FFD700]' />
            <img src={mcx} alt="mcx" width="300px" className='rounded-lg shadow-lg shadow-[#FFD700]' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Screen5
